<template>
  <i :class="['fa', icon ]" :style="[{ color }, size ? { fontSize: size } : null]" />
</template>
<script>

export default {
  props: {
    icon: {
      type: String,
      default: 'picture-o',
    },
    color: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped>

</style>