var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column tl-rcc tl-bubble" }, [
    _c("div", { staticClass: "flex-fill" }, [
      _c("h5", [_vm._v("POZNÁMKY K PRŮBĚHU AKTIVITY?")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.diary.notes))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-fill" }, [
      _c("h5", [_vm._v("CO JSEM SE Z TÉTO AKTIVITY NAUČIL?")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.diary.learned))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-fill" }, [
      _c("h5", [_vm._v("NA ČEM BUDU V TÉTO OBLASTI PRACOVAT?")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.diary.future))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-fill" }, [
      _c("h5", [
        _vm._v("CO ZE ZÍSKANÝCH POZNATKŮ A JAK BUDU APLIKOVAT V PRAXI?")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.diary.practice))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }