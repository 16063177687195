import entities from './entities'

export default {
    state: {
        // define app global state variables
    },
    mutations: {
        // define app global mutations
    },
    getters: {
        // define app global getters
    },
    actions: {
        // define app global actions
    },
    modules: {
        entities
    }
}