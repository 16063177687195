<template>
  <div class="comment-bubble mb-1 mt-1">
    <div class="d-flex flex-row">
      <div class="flex-grow-1"><small>{{ comment.user.name }} {{ comment.user.surname }}</small></div>
      <div><small>{{ comment.post_date | moment('DD. MMMM YYYY, hh:mm') }}</small></div>
    </div>
    <div>{{ comment.text }}</div>
  </div>
</template>
<script>
export default {
  props: {
    comment: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style scoped>

</style>