import Vue from 'vue';
import Vuex from 'vuex';
import VueI18nFilter from 'vue-i18n-filter';
import VueMoment from 'vue-moment';
import VueMq from 'vue-mq';
import VueEasyLightbox from 'vue-easy-lightbox';
import * as moment from 'moment';
import 'moment/locale/cs';

import CategoriesFanComponent from './components/common/CategoriesFanComponent.vue';
import TimelineComponent from './components/timeline/TimelineComponent.vue';

import storeConfig from './store';

Vue.use(Vuex);
Vue.use(VueI18nFilter);
Vue.use(VueMoment, { moment });
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
});
Vue.use(VueEasyLightbox);

const store = new Vuex.Store(storeConfig);

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  el: '#app',
  store,
  components: {
    CategoriesFanComponent,
    TimelineComponent,
  },
});
