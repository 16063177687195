import axios from 'axios';

export default {
  getActivity: (id) => axios.get('index.php', { params: { r: 'timeline/get-activity', id } }),
  getCategory: (id) => axios.get('index.php', { params: { r: 'timeline/get-category', id } }),
  getUser: (id) => axios.get('index.php', { params: { r: 'timeline/get-user', id } }),
  getTimeline: (limit, offset) => axios.get('index.php', { params: { r: 'timeline/get-timeline', limit, offset } }),
  getComments: (timelineid, limit, offset) => axios.get('index.php', { params: { r: 'timeline/get-comments', timelineid, limit, offset } }),
  getDiary: (userActivity, limit, offset) => axios.get('index.php', { params: { r: 'timeline/get-diary', useractivity: userActivity, limit, offset } }),
  getDiaryFiles: (diaryId, limit, offset) => axios.get('index.php', { params: { r: 'timeline/get-diary-files', diaryid: diaryId, limit, offset } }),
  getLikes: (timelineid) => axios.get('index.php', { params: { r: 'timeline/get-likes', timelineid } }),
  addLike: (timelineid) => axios.post('index.php', null, { params: { r: 'timeline/add-like', timelineid } }),
  removeLike: (timelineid) => axios.post('index.php', null, { params: { r: 'timeline/remove-like', timelineid } }),
  addComment: (timelineid, text) => axios.post('index.php', { timelineid, text }, { params: { r: 'timeline/add-comment' } }),
};
