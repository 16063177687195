var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        _vm._l(_vm.data, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "d-flex flex-row tl-item m-1 p-1",
              style: { backgroundColor: item.category.color + "10" }
            },
            [
              ["lg"].includes(_vm.$mq)
                ? _c("div", { staticClass: "d-flex flex-column tl-rh" }, [
                    _c("h4", { staticClass: "m-1" }, [
                      _vm._v(
                        _vm._s(item.user.name) + " " + _vm._s(item.user.surname)
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "m-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(item.share_date, "DD. MMMM YYYY")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "inspect-bubble m-1" }, [
                      _vm._v(_vm._s(item.share_text))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-muted" })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column tl-rc" },
                [
                  !["lg"].includes(_vm.$mq)
                    ? _c(
                        "timeline-row-component",
                        {
                          attrs: {
                            "icon-name": "fa-user",
                            "icon-color": "orange"
                          }
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(item.user.name) +
                                " " +
                                _vm._s(item.user.surname)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  item.share_date,
                                  "DD. MMMM YYYY"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(item.share_text))])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "timeline-row-component",
                    {
                      style: { backgroundColor: item.category.color + "55" },
                      attrs: {
                        "icon-name": "fa-square",
                        "icon-color": item.category.color
                      }
                    },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(item.activity.act_name) + " "),
                        _c("small", [_vm._v(_vm._s(item.activity.act_desc))])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(item.diary, function(diary) {
                    return [
                      _c(
                        "timeline-row-component",
                        {
                          key: diary.id,
                          attrs: {
                            "icon-name": "fa-circle",
                            "icon-color": "orange",
                            timestamp: diary.date,
                            "icon-position": "middle"
                          }
                        },
                        [
                          _c("timeline-diary-component", {
                            attrs: { diary: diary }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "timeline-row-component",
                        {
                          key: diary.id + "attachments",
                          attrs: {
                            "icon-name": "fa-paperclip",
                            "icon-color": "darkgray"
                          }
                        },
                        [
                          _c("div", [_vm._v("Attachments")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(diary.files, function(file) {
                              return _c("timeline-attachment-component", {
                                key: file.id,
                                staticClass: "m-1",
                                attrs: { file: file }
                              })
                            }),
                            1
                          )
                        ]
                      )
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    "timeline-row-component",
                    {
                      attrs: {
                        "icon-name": "fa-comments",
                        "icon-color": "darkgray"
                      }
                    },
                    [
                      _c("div", [_vm._v("Comments")]),
                      _vm._v(" "),
                      _vm._l(item.comments, function(comment) {
                        return _c("timeline-comment-component", {
                          key: comment.id,
                          attrs: { comment: comment }
                        })
                      }),
                      _vm._v(" "),
                      item.commentInputVisible
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-column mt-3" },
                            [
                              _c("textarea", {
                                ref: "commentInput" + item.id,
                                refInFor: true,
                                staticClass: "form-control comment-texarea"
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "m-1" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm mt-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addCommentExecute(
                                          item,
                                          _vm.$refs["commentInput" + item.id][0]
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("odeslat")]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row mt-3" },
                        [
                          _c("timeline-likes-component", {
                            staticClass: "m-1",
                            attrs: {
                              list: item.likes,
                              user: _vm.user,
                              disabled: item.likesUpating
                            },
                            on: {
                              like: function($event) {
                                return _vm.toggleLike($event, item)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-sm m-1",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.showCommentInputExecute(item)
                                }
                              }
                            },
                            [_vm._v("přidat komentář")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light btn-sm m-1",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.loadMoreCommentsExecute(item)
                                }
                              }
                            },
                            [_vm._v("více")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                2
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          attrs: { spinner: "waveDots" },
          on: { infinite: _vm.infiniteHandler }
        },
        [
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
            _vm._v("No more message")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }, [
            _vm._v("No results message")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }