<template>
  <div class="img-fluid img-thumbnail rounded center p-1" :style="{ width: `${width + 1}px`, height: `${height + 1}px`}">
    <div v-if="isImage || isVideo">
      <img
        :src="file.file_path"
        :alt="file.file_desc"
        :style="{ 'max-width': `${width}px`, 'max-height': `${height}px` }"
        @click="showLightBox"
      >
      <vue-easy-lightbox
        :visible="visible"
        :imgs="file.file_path"
        @hide="hideLightBox"
      />
    </div>
    <div v-if="!isImage">
      <div class="d-flex flex-column">
        <a :href="file.file_path">
          <icon-component class="flex-grow-1" :icon="thumbnail" :size="`${width/2}px`" color="#dddddd" />
          <div><small class="text-muted">{{ file.file_name }}</small></div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import IconComponent from '../common/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    isImage() {
      return this.isSupported(['jpg', 'png', 'bmp']);
    },
    isVideo() {
      return this.isSupported(['avi', 'mp4', 'mpeg', 'mvk']);
    },
    thumbnail() {
      switch (this.file.file_type) {
        case 'jpg':
        case 'png':
        case 'bmp': return 'fa-file-image-o';
        case 'pdf': return 'fa-file-pdf-o';
        case 'doc':
        case 'docx': return 'fa-file-word-o';
        case 'xls':
        case 'xlsx': return 'fa-file-excel-o';
        case 'zip':
        case 'rar': return 'fa-file-archive-o';
        case 'avi':
        case 'mp4':
        case 'mpeg': return 'fa-file-video-o';
        default: return 'fa-file-o';
      }
    },
  },

  methods: {
    isSupported(filetypes) {
      return this.file && filetypes.includes(this.file.file_type);
    },
    showLightBox() {
      this.visible = true;
    },
    hideLightBox() {
      this.visible = false;
    },
  },
};
</script>
<style scoped>
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>