<template>
  <div class="d-flex flex-row">
    <button type="button" class="btn btn-sm" :class="{ 'btn-primary': liked, 'btn-secondary': !liked }" @click="$emit('like', !liked)">
      <icon-component
        icon="fa-thumbs-up"
      />
      {{ list.length }}
    </button>
  </div>
</template>
<script>
import IconComponent from '../common/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Number,
      default: null,
    },
  },
  computed: {
    liked() {
      return this.list.includes(this.user);
    },
  },
};
</script>
<style scoped>

</style>