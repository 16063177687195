var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-bubble mb-1 mt-1" }, [
    _c("div", { staticClass: "d-flex flex-row" }, [
      _c("div", { staticClass: "flex-grow-1" }, [
        _c("small", [
          _vm._v(
            _vm._s(_vm.comment.user.name) +
              " " +
              _vm._s(_vm.comment.user.surname)
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("small", [
          _vm._v(
            _vm._s(
              _vm._f("moment")(_vm.comment.post_date, "DD. MMMM YYYY, hh:mm")
            )
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.comment.text))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }