<template>
  <div class="d-flex flex-grow-1 flex-row tl-rcr">
    <div class="d-flex flex-column tl-rch">
      <div
        class="d-flex flex-column align-items-start flex-grow-1 tl-rch-grayline"
        :class="{
          'tl-rch-middle': iconPosition === 'middle',
          'tl-rch-top': iconPosition === 'top',
          'tl-rch-bottom': iconPosition === 'bottom',
        }"
      >
        <div class="tl-row-timestamp">
          <small v-if="timestamp">{{ timestamp | moment("dddd, DD. MMMM YY' hh:mm:ss") }}</small>
        </div>
        <icon-component
          class="tl-rch-icon"
          :icon="iconName"
          :color="iconColor"
          size="8px"
        />
      </div>
    </div>
    <div class="d-flex flex-column tl-rcc p-1">
      <slot />
    </div>
  </div>
</template>
<script>
import IconComponent from '../common/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  props: {
    iconName: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'top',
    },
    timestamp: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped>
  .tl-row-timestamp-wrapper {
    position: relative;
    display: inline-block;
  }

  .tl-row-timestamp {
    position: absolute;
    transform: translate(-210px, 0);
    padding: 1.5rem;
    width: 300px;
  }
</style>