export default {
    namespaced: true,
    state: {
        activities: [],
        categories: [],
        users: [],
    },
    mutations: {
        storeActivity: (state, activity) => state.activities.push(activity),
        storeUser: (state, user) => state.users.push(user),
        storeCategory: (state, category) => state.categories.push(category)
    },
    getters: {
        findActivity: state => id => {
            const activity = state.activities.find(item => item.id == id)
            return activity
        },
        findCategory: state => id => {
            const category = state.categories.find(item => item.id == id)
            return category
        },
        findUser: state => id => {
            const user = state.users.find(item => item.id == id);
            return user;
        }
    }
}