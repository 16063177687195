var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-grow-1 flex-row tl-rcr" }, [
    _c("div", { staticClass: "d-flex flex-column tl-rch" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-start flex-grow-1 tl-rch-grayline",
          class: {
            "tl-rch-middle": _vm.iconPosition === "middle",
            "tl-rch-top": _vm.iconPosition === "top",
            "tl-rch-bottom": _vm.iconPosition === "bottom"
          }
        },
        [
          _c("div", { staticClass: "tl-row-timestamp" }, [
            _vm.timestamp
              ? _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.timestamp,
                        "dddd, DD. MMMM YY' hh:mm:ss"
                      )
                    )
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("icon-component", {
            staticClass: "tl-rch-icon",
            attrs: { icon: _vm.iconName, color: _vm.iconColor, size: "8px" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-column tl-rcc p-1" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }