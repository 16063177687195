var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "img-fluid img-thumbnail rounded center p-1",
      style: { width: _vm.width + 1 + "px", height: _vm.height + 1 + "px" }
    },
    [
      _vm.isImage || _vm.isVideo
        ? _c(
            "div",
            [
              _c("img", {
                style: {
                  "max-width": _vm.width + "px",
                  "max-height": _vm.height + "px"
                },
                attrs: { src: _vm.file.file_path, alt: _vm.file.file_desc },
                on: { click: _vm.showLightBox }
              }),
              _vm._v(" "),
              _c("vue-easy-lightbox", {
                attrs: { visible: _vm.visible, imgs: _vm.file.file_path },
                on: { hide: _vm.hideLightBox }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isImage
        ? _c("div", [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "a",
                { attrs: { href: _vm.file.file_path } },
                [
                  _c("icon-component", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      icon: _vm.thumbnail,
                      size: _vm.width / 2 + "px",
                      color: "#dddddd"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.file.file_name))
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }