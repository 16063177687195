var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: ["fa", _vm.icon],
    style: [{ color: _vm.color }, _vm.size ? { fontSize: _vm.size } : null]
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }