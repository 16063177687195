<template>
  <div class="d-flex flex-column tl-rcc tl-bubble">
    <div class="flex-fill"><h5>POZNÁMKY K PRŮBĚHU AKTIVITY?</h5> <p>{{ diary.notes }}</p></div>
    <div class="flex-fill"><h5>CO JSEM SE Z TÉTO AKTIVITY NAUČIL?</h5> <p>{{ diary.learned }}</p></div>
    <div class="flex-fill"><h5>NA ČEM BUDU V TÉTO OBLASTI PRACOVAT?</h5> <p>{{ diary.future }}</p></div>
    <div class="flex-fill"><h5>CO ZE ZÍSKANÝCH POZNATKŮ A JAK BUDU APLIKOVAT V PRAXI?</h5> <p>{{ diary.practice }}</p></div>
  </div>
</template>
<script>
export default {
  props: {
    diary: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style scoped>
  .h5 {
    font-size: 12pt !important;
  }
</style>