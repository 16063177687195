/* eslint-disable vue/html-indent */
<template>
  <div>
    <div class="d-flex flex-column">
      <div
        v-for="item in data"
        :key="item.id"
        class="d-flex flex-row tl-item m-1 p-1"
        :style="{ backgroundColor: item.category.color+'10' }"
      >
        <!-- POST HEADER on large devices-->
        <div v-if="['lg'].includes($mq)" class="d-flex flex-column tl-rh">
          <h4 class="m-1">{{ item.user.name }} {{ item.user.surname }}</h4>
          <div class="m-1">{{ item.share_date | moment("DD. MMMM YYYY") }}</div>
          <div class="inspect-bubble m-1">{{ item.share_text }}</div>
          <div class="text-muted">
            <!-- <div><small>timeline id: {{ item.id }}</small></div>
            <div><small>activity id: {{ item.activity.id }}</small></div>
            <div><small>user_activity id: {{ item.user_activity }}</small></div>
            <div><small>category id: {{ item.category.id }}</small></div> -->
          </div>
        </div>

        <div class="d-flex flex-column tl-rc">
          <!-- POST HEADER on small devices-->
          <timeline-row-component
            v-if="!['lg'].includes($mq)"
            :icon-name="'fa-user'"
            :icon-color="'orange'"
          >
            <h3>{{ item.user.name }} {{ item.user.surname }}</h3>
            <div>{{ item.share_date | moment("DD. MMMM YYYY") }}</div>
            <div>{{ item.share_text }}</div>
          </timeline-row-component>

          <!-- ACTIVITY RECORD-->
          <timeline-row-component
            :icon-name="'fa-square'"
            :icon-color="item.category.color"
            :style="{ backgroundColor: item.category.color+'55' }"
          >
            <h4>{{ item.activity.act_name }} <small>{{ item.activity.act_desc }}</small></h4>
          </timeline-row-component>

          <!-- DIARY RECORDS -->
          <template v-for="diary in item.diary">
            <timeline-row-component
              :key="diary.id"
              :icon-name="'fa-circle'"
              :icon-color="'orange'"
              :timestamp="diary.date"
              :icon-position="'middle'"
            >
              <timeline-diary-component :diary="diary" />
            </timeline-row-component>

            <!-- DIARY ATTACHMENTS RECORDS -->
            <timeline-row-component
              :key="diary.id + 'attachments'"
              :icon-name="'fa-paperclip'"
              :icon-color="'darkgray'"
            >
              <div>Attachments</div>
              <div class="d-flex flex-wrap">
                <timeline-attachment-component
                  v-for="file in diary.files"
                  :key="file.id"
                  :file="file"
                  class="m-1"
                />
              </div>
            </timeline-row-component>
          </template>

          <!-- COMMENTS RECORDS -->
          <timeline-row-component
            :icon-name="'fa-comments'"
            :icon-color="'darkgray'"
          >
            <div>Comments</div>
            <timeline-comment-component
              v-for="comment in item.comments"
              :key="comment.id"
              :comment="comment"
            />
            <div v-if="item.commentInputVisible" class="d-flex flex-column mt-3">
              <textarea :ref="'commentInput' + item.id" class="form-control comment-texarea" />
              <div class="m-1"><button type="button" class="btn btn-primary btn-sm mt-3" @click="addCommentExecute(item, $refs[`commentInput${item.id}`][0])">odeslat</button></div>
            </div>
            <div class="d-flex flex-row mt-3">
              <timeline-likes-component class="m-1" :list="item.likes" :user="user" :disabled="item.likesUpating" @like="toggleLike($event, item)" />
              <button type="button" class="btn btn-light btn-sm m-1" @click="showCommentInputExecute(item)">přidat komentář</button>
              <button type="button" class="btn btn-light btn-sm m-1" @click="loadMoreCommentsExecute(item)">více</button>
            </div>
          </timeline-row-component>
        </div>
      </div>
    </div>

    <infinite-loading
      spinner="waveDots"
      @infinite="infiniteHandler"
    >
      <div slot="no-more">No more message</div>
      <div slot="no-results">No results message</div>
    </infinite-loading>
  </div>
</template>
<script>

import InfiniteLoading from 'vue-infinite-loading';
import entities from '../../mixins/entities';
import TimelineRowComponent from './TimelineRowComponent.vue';
import TimelineAttachmentComponent from './TimelineAttachmentComponent.vue';
import TimelineDiaryComponent from './TimelineDiaryComponent.vue';
import TimelineCommentComponent from './TimelineCommentComponent.vue';
import TimelineLikesComponent from './TimelineLikesComponent.vue';

export default {
  components: {
    InfiniteLoading,
    TimelineRowComponent,
    TimelineAttachmentComponent,
    TimelineDiaryComponent,
    TimelineCommentComponent,
    TimelineLikesComponent,
  },
  filters: {
    pretty: (value) => JSON.stringify(value, null, 2),
  },
  mixins: [entities],
  props: {
    user: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    data: [],
    page: 0,
  }),
  methods: {
    async infiniteHandler($state) {
      try {
        const data = await this.getTimeline(3, this.page);
        if (data.length > 0) {
          for (const item of data) {
            // user
            item.user = await this.getUser(item.user);

            // activity
            item.activity = await this.getActivity(item.activity);

            // category
            item.category = await this.getCategory(item.category);

            // diaries
            item.diaryPage = 0;
            item.diary = [];
            this.loadMoreDiaryExecute(item);

            // comments
            item.commentsPage = 0;
            item.comments = [];
            this.loadMoreCommentsExecute(item);

            // likes
            item.likes = await this.getLikes(item.id);
          }

          this.data.push(...data);
          this.page += data.length;

          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    async loadMoreDiaryExecute(item) {
      const diaries = await this.getDiary(item.user_activity, 10, item.diaryPage);

      // diaries files
      for (const diary of diaries) {
        diary.filesPage = 0;
        diary.files = await this.getDiaryFiles(diary.id, 3, diary.filesPage);
        diary.filesPage += diary.files.length;
      }

      item.diary.push(...diaries);
      item.diaryPage += item.diary.length;
    },

    async loadMoreCommentsExecute(item) {
      const comments = await this.getComments(item.id, 3, item.commentsPage);

      for (const comment of comments) {
        comment.user = await this.getUser(comment.user);
      }

      item.comments.push(...comments);
      item.commentsPage += comments.length;
    },

    async showCommentInputExecute(item) {
      item.commentInputVisible = true;
      this.$forceUpdate();
    },

    async addCommentExecute(item, input) {
      item.commentInputVisible = false;
      await this.addComment(item.id, input.value);
      this.loadMoreCommentsExecute(item);
      this.$forceUpdate();
    },

    async toggleLike(like, item) {
      if (item.likesUpating) {
        return;
      }

      try {
        item.likesUpating = true;
        try {
          if (like) {
            await this.addLike(item.id);
          } else {
            await this.removeLike(item.id);
          }

          item.likes = await this.getLikes(item.id);
        } finally {
          item.likesUpating = false;
        }
      } catch (e) {
        // do nothing
      }
    },
  },
};
</script>
<style>
    .tl-item {
      border-radius: 7px;
      -webkit-box-shadow: 0px 0px 18px 3px rgba(0,0,0,0.02);
      -moz-box-shadow: 0px 0px 18px 3px rgba(0,0,0,0.02);
      box-shadow: 0px 0px 18px 3px rgba(0,0,0,0.02);
    }

    .tl-rh {
        min-width: 200px !important;
        max-width: 200px !important;
        padding: 10px;
    }

    .tl-rc {
        padding-left: 10px;
    }

    .tl-rcr {
        min-height: 50px;
        border-radius: 7px;
    }

    .tl-rch {
        min-width: 50px !important;
        padding: 0px 20px 0px 20px;
    }

    .tl-rch-grayline {
        background-color: lightgray;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .tl-rch-icon {
        transform: scale(4.0);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .tl-rch-middle {
      justify-content: center;
    }

    .tl-rch-top {
      justify-content: flex-start;
    }

    .tl-rch-bottom {
      justify-content: flex-end;
    }

    .tl-rcc {
        margin: 10px;
        padding: 10px;
    }

    .tl-bubble {
        position: relative;
        background: #eeeeee;
        border-radius: .4em;
        margin-left: 0px;
    }

    .tl-bubble:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-right-color: #eeeeee;
        border-left: 0;
        margin-top: -20px;
        margin-left: -20px;
    }

    .inspect-bubble {
        position: relative;
        background: orange;
        border-radius: .4em;
        padding: 15px;
    }

    .inspect-bubble:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-left-color: orange;
        border-right: 0;
        border-top: 0;
        margin-top: -10px;
        margin-right: -20px;
    }

    .comment-bubble {
        position: relative;
        background: #eeeeee;
        border-radius: .4em;
        padding: 10px;
        min-width: 400px;
        min-height: 100px;
    }

    .comment-bubble:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-color: #eeeeee;
        border-left: 0;
        margin-top: -5px;
        margin-left: -5px;
    }

    .comment-texarea {
      border-radius: 5px;
    }
</style>