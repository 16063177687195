import { mapGetters, mapMutations } from 'vuex';

import entities from '../services/entities';

export default {
  computed: {
    ...mapGetters('entities', ['findActivity', 'findUser', 'findCategory']),
  },
  methods: {
    ...mapMutations('entities', ['storeActivity', 'storeUser', 'storeCategory']),

    async getActivity(id) {
      let activity = this.findActivity(id);
      if (!activity) {
        activity = (await entities.getActivity(id)).data;
        this.storeActivity(activity);
      }
      return activity;
    },

    async getUser(id) {
      let user = this.findUser(id);
      if (!user) {
        user = (await entities.getUser(id)).data;
        this.storeUser(user);
      }
      return user;
    },

    async getCategory(id) {
      let category = this.findCategory(id);
      if (!category) {
        category = (await entities.getCategory(id)).data;
        this.storeCategory(category);
      }
      return category;
    },

    async getTimeline(limit, offset) {
      const response = await entities.getTimeline(limit, offset);
      return response.data;
    },

    async getComments(timelineid, limit, offset) {
      const response = await entities.getComments(timelineid, limit, offset);
      return response.data;
    },

    async getDiary(userActivity, limit, offset) {
      const response = await entities.getDiary(userActivity, limit, offset);
      return response.data;
    },

    async getDiaryFiles(diaryId, limit, offset) {
      const response = await entities.getDiaryFiles(diaryId, limit, offset);
      return response.data;
    },

    async getLikes(timelineid) {
      const response = await entities.getLikes(timelineid);
      return response.data;
    },

    addLike(timelineid) {
      return entities.addLike(timelineid);
    },

    removeLike(timelineid) {
      return entities.removeLike(timelineid);
    },

    addComment(timelineid, text) {
      return entities.addComment(timelineid, text);
    },
  },
};
