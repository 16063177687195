var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("object", {
    ref: "fan",
    staticClass: "flex-fill",
    staticStyle: { "max-height": "500px !important" },
    attrs: { type: "image/svg+xml", data: _vm.file }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }